import { DateRange } from 'react-day-picker'

export interface StoredDateState {
  dateRange: DateRange
  preset: string
  isAllTime?: boolean
}

export const convertDateRangeToStoredRange = (range: DateRange): DateRange => ({
  from: range.from ?? undefined,
  to: range.to ?? new Date(),
})

export const isAllTime = (dateState: StoredDateState) => {
  return dateState.isAllTime || dateState.preset === 'all-time'
}

export const getCurrentDateRange = (dateState: StoredDateState): DateRange => {
  if (isAllTime(dateState)) {
    return {
      from: new Date('2024-01-01'),
      to: new Date(),
    }
  }
  return dateState.dateRange
}
