import { useMemo, useState } from 'react'
import moment from 'moment'
import { useUser } from '@clerk/clerk-react'
import { DateRange } from 'react-day-picker'

import { Muted, H2 } from '@/components/ui/typography'
import { useFetchFeedbackStats } from '@/services/api/feedbackPoints.api'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Icon } from '@/components/ui/icon'
import { Button } from '@/components/ui/button'
import { LayoutTile } from '@/components/layout'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { DatePicker } from '@/components/common/date-picker'
import { useGoogleOAuth } from '@/hooks/useGoogleOAuth'
import { MOCK_SERVER_ENABLED } from '@/services/config/env'
import { StoredDateState } from '@/types/DateState'
import { ErrorTile } from '@/components/common/error-tile'
import { DashboardContent } from '@/components/dashboard-content'

export const PersonalDashboard = () => {
  const { user } = useUser()
  const { connectGoogle } = useGoogleOAuth({
    redirectUrl: `${window.location.origin}/personal-dashboard`,
  })

  // Calculate default dates
  const defaultDateRange = useMemo(
    () => ({
      from: moment().subtract(3, 'months').toDate(),
      to: new Date(),
    }),
    [],
  )

  const [storedDateState, setStoredDateState] =
    useLocalStorage<StoredDateState>('personal-dashboard.date-state', {
      dateRange: defaultDateRange,
      preset: 'last-3-months',
    })

  // Recalculate relative dates if using a relative preset
  const initialDateRange = useMemo(() => {
    if (storedDateState.preset === 'last-3-months') {
      return defaultDateRange
    }
    return storedDateState.dateRange
  }, [storedDateState, defaultDateRange])

  const [dateRange, setDateRange] = useState<DateRange>(initialDateRange)
  const [selectedPreset, setSelectedPreset] = useState(storedDateState.preset)

  const {
    data: feedbackStats,
    isLoading: isLoadingStats,
    error,
  } = useFetchFeedbackStats(undefined, storedDateState.dateRange)

  const hasGoogleConnected = useMemo(
    () =>
      !!user?.externalAccounts?.find((acc) => acc.provider === 'google')
        ?.emailAddress,
    [user],
  )

  const shouldShowGoogleWidget = useMemo(
    () => !MOCK_SERVER_ENABLED && !hasGoogleConnected,
    [hasGoogleConnected],
  )

  if (error) return <ErrorTile message={error.message} />

  return (
    <LayoutTile className="bg-transparent p-0 sm:p-0">
      {shouldShowGoogleWidget && (
        <Alert className="mb-4">
          <div className="flex flex-col sm:flex-row items-center gap-4">
            <Icon
              name="CalendarFold"
              className="h-4 w-4 text-green-600 flex-shrink-0"
            />
            <div className="flex flex-col sm:flex-row flex-1 items-center gap-4">
              <div className="text-center sm:text-left w-full">
                <AlertTitle className="text-green-600">
                  Fiddy is waiting for you!
                </AlertTitle>
                <AlertDescription>
                  <Muted>
                    Our intelligent robot Fiddy needs access to your Calendar to
                    bring you the best experience.
                  </Muted>
                </AlertDescription>
              </div>
              <Button
                variant="default"
                className="flex-shrink-0 bg-[#43A047] hover:bg-[#2E7D32] w-full sm:w-auto"
                onClick={connectGoogle}
              >
                Connect Calendar
              </Button>
            </div>
          </div>
        </Alert>
      )}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-2">
        <div>
          <H2 className="welcome-title">
            Welcome to FidForward
            {user ? `, ${user.firstName}!` : '!'}
          </H2>
          <Muted className="mt-1">
            <div className="personal-dashboard-stats">
              You can find here all your consolidated data.
            </div>
          </Muted>
        </div>
        <div className="flex-shrink-0 mt-4 sm:mt-0">
          <DatePicker
            value={dateRange}
            selectedPreset={selectedPreset}
            onChange={(range, preset, isAllTime) => {
              setDateRange(range)
              setSelectedPreset(preset)
              setStoredDateState({
                dateRange: range,
                preset: preset,
                isAllTime: !!isAllTime,
              })
            }}
            includeAllTime
          />
        </div>
      </div>

      <DashboardContent
        feedbackStats={feedbackStats}
        isLoadingStats={isLoadingStats}
        viewType="personal"
        dateRange={dateRange}
      />
    </LayoutTile>
  )
}
