import React, { useMemo, useState } from 'react'
import moment from 'moment'
import lodash from 'lodash'
import colors from 'tailwindcss/colors'
import { DateRange } from 'react-day-picker'

import { Tile } from '@/components/common/tile'
import { Muted, H4 } from '@/components/ui/typography'
import { TooltipWithIcon } from '@/components/common/tooltip'
import BarChart from '@/components/common/bar-chart'
import LineChart, { LINE_CHART_COLORS } from '@/components/common/line-chart'
import { PlayCircle } from 'lucide-react'
import { Checkbox } from '@/components/ui/checkbox'

import { useScreenDetector } from '@/hooks/useScreenDetector'
import { formatDecimalToPercentage } from '@/services/utils/formatters'
import { average } from '@/services/utils/math'

import { FeedbackStats } from '@/types/FeedbackStats'
import { Progress } from '@/components/ui/progress'

interface MetricTileProps {
  title: string
  value: string | number
  isLoading: boolean
  valueColor?: string
  tooltipContent: string
  tooltipType?: 'info' | 'warning' | 'error'
}

export const MetricTile: React.FC<MetricTileProps> = ({
  title,
  value,
  isLoading,
  valueColor = 'text-primary',
  tooltipContent,
  tooltipType = 'info',
}) => (
  <Tile className="min-h-20" isLoading={isLoading}>
    <div className="flex items-center gap-2">
      <Muted>{title}</Muted>
      <TooltipWithIcon content={tooltipContent} type={tooltipType} />
    </div>
    <H4 className={valueColor}>{value}</H4>
  </Tile>
)

interface DashboardContentProps {
  feedbackStats?: FeedbackStats
  isLoadingStats: boolean
  viewType: 'personal' | 'management'
  dateRange: DateRange
}

export const DashboardContent: React.FC<DashboardContentProps> = ({
  feedbackStats,
  isLoadingStats,
  viewType,
  dateRange,
}) => {
  const { isMobile } = useScreenDetector()
  const dateIntervalsToShow = useMemo(() => {
    if (!dateRange.from || !dateRange.to) return []

    const intervals = []
    const current = moment(dateRange.from).startOf('week')
    const end = moment(dateRange.to).endOf('week')

    while (current.isSameOrBefore(end)) {
      intervals.push(current.toDate())
      current.add(1, 'week')
    }

    return isMobile ? intervals.slice(-4) : intervals
  }, [dateRange, isMobile])

  const checklistComplianceValue = useMemo(() => {
    return feedbackStats?.checklistCompliance ?? 0
  }, [feedbackStats])

  const companyValues = useMemo(() => {
    if (!feedbackStats?.valuesAverage) return []
    return Object.entries(feedbackStats.valuesAverage).map(([name, score]) => ({
      name,
      score,
    }))
  }, [feedbackStats?.valuesAverage])

  const sortedCompanyValues = useMemo(() => {
    return [...companyValues].sort((a, b) => a.name.localeCompare(b.name))
  }, [companyValues])

  const [isCumulative, setIsCumulative] = useState(false)

  // First, let's extract the values keys from the actual data
  const valuesKeys = useMemo(() => {
    // Extract keys from the first valid score in weekDetails
    const firstValidScore = feedbackStats?.weekDetails.find(
      (week) => week.companyValuesScore?.[0],
    )?.companyValuesScore?.[0]
    return firstValidScore ? Object.keys(firstValidScore) : []
  }, [feedbackStats?.weekDetails])

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <MetricTile
          title="Feedback Points"
          value={
            (feedbackStats?.done?.meets_checklist ?? 0) +
            (feedbackStats?.done?.does_not_meet_checklist ?? 0)
          }
          isLoading={isLoadingStats}
          tooltipContent="Number of Feedback Points within the selected time period"
        />
        <MetricTile
          title="Missed Feedback"
          value={formatDecimalToPercentage(
            feedbackStats?.missedProactiveFeedback ?? 0,
            0,
          )}
          isLoading={isLoadingStats}
          valueColor={
            (feedbackStats?.missedProactiveFeedback ?? 0) > 0.3
              ? 'text-rose-400'
              : 'text-green-600'
          }
          tooltipContent="Percentage of missed feedback requests from Fiddy"
          tooltipType="info"
        />
        <MetricTile
          title="Quality score"
          value={formatDecimalToPercentage(checklistComplianceValue, 0)}
          isLoading={isLoadingStats}
          valueColor={
            checklistComplianceValue < 0.7 ? 'text-rose-400' : 'text-green-600'
          }
          tooltipContent="Percentage of quality checklist criteria that are met"
          tooltipType="info"
        />
        <Tile
          className="min-h-20 flex flex-col justify-between"
          onClick={() =>
            window.open(
              'https://www.youtube.com/watch?v=1GzBSuNJdHA&list=PLc5u-ik0Rm5ekzrCpESsanA4zD9kAaV55&index=1',
              '_blank',
            )
          }
        >
          <div className="flex items-center gap-2">
            <Muted>Questions on how to use?</Muted>
          </div>
          <div className="flex items-center gap-2">
            <PlayCircle className="w-5 h-5 text-primary" />
            <span className="font-medium">Check our tutorials</span>
          </div>
        </Tile>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
        <Tile className="lg:col-span-3 h-[300px]" isLoading={isLoadingStats}>
          <div>
            <Muted>Feedback Points over time</Muted>
          </div>
          <BarChart
            data={dateIntervalsToShow.map((week) => {
              const weekDetails = feedbackStats?.weekDetails.find(
                (elem) => elem.week === moment(week).week(),
              )
              const meetsChecklist = weekDetails?.done.meets_checklist ?? 0
              const doesNotMeetChecklist =
                weekDetails?.done.does_not_meet_checklist ?? 0
              return {
                label: `W${moment(week).week()}`,
                meetsChecklist,
                doesNotMeetChecklist,
              }
            })}
            bars={['meetsChecklist', 'doesNotMeetChecklist']}
            config={{
              meetsChecklist: {
                label: 'Meets quality threshold',
                color: colors.green['600'],
              },
              doesNotMeetChecklist: {
                label: "Doesn't meet quality threshold",
                color: colors.red['200'],
              },
            }}
            activeIndex={dateIntervalsToShow.length}
            stacked={true}
          />
        </Tile>
        <Tile className="lg:col-span-2 h-[300px]" isLoading={isLoadingStats}>
          <div className="flex items-center gap-2">
            <Muted>Average values</Muted>
            <TooltipWithIcon
              content={
                viewType === 'personal'
                  ? 'The average score others have given you (if more than 5 feedback points)'
                  : 'The average scores attributed to the selected team members (if more than 5 feedback points)'
              }
            />
          </div>
          <div className="flex flex-1 flex-col gap-4 justify-center">
            {sortedCompanyValues.length > 0 ? (
              sortedCompanyValues.map((value) => (
                <div
                  key={value.name}
                  className="flex items-center justify-between w-full"
                >
                  <span
                    className="w-32 text-sm text-muted-foreground mr-2 truncate font-medium"
                    title={value.name}
                  >
                    {value.name}
                  </span>
                  <div className="flex-1 mx-2">
                    <Progress value={value.score * 20} className="h-4" />
                  </div>
                  <span className="text-sm font-medium">
                    {value.score.toFixed(1)}
                  </span>
                </div>
              ))
            ) : (
              <div className="text-center text-muted-foreground">
                {viewType === 'personal'
                  ? 'You need at least 5 complete feedback points from others to see your average values'
                  : 'No values data available for the selected team members'}
              </div>
            )}
          </div>
        </Tile>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <Tile className="h-[300px]" isLoading={isLoadingStats}>
          <div className="flex items-center justify-between">
            <Muted>Values over time</Muted>
            {valuesKeys.length > 0 && (
              <div className="flex items-center gap-2">
                <Checkbox
                  id="movingAverage"
                  checked={isCumulative}
                  onCheckedChange={(checked) =>
                    setIsCumulative(checked as boolean)
                  }
                />
                <label
                  htmlFor="movingAverage"
                  className="text-sm text-muted-foreground cursor-pointer"
                >
                  3-week average
                </label>
              </div>
            )}
          </div>
          {valuesKeys.length > 0 ? (
            <LineChart
              data={dateIntervalsToShow.map((week, weekIndex) => {
                const currentWeek = moment(week).week()

                if (isCumulative) {
                  // For 3-week moving average view
                  const startIndex = Math.max(0, weekIndex - 2)
                  const weeksToAverage = dateIntervalsToShow.slice(
                    startIndex,
                    weekIndex + 1,
                  )
                  return {
                    label: `W${currentWeek}`,
                    ...lodash.fromPairs(
                      valuesKeys.map((key) => [
                        key,
                        average(
                          weeksToAverage.flatMap(
                            (w) =>
                              feedbackStats?.weekDetails
                                .find((elem) => elem.week === moment(w).week())
                                ?.companyValuesScore?.filter(Boolean) // Filter out null/undefined scores
                                ?.map((sentiment) => sentiment[key]) ?? [],
                          ),
                        ),
                      ]),
                    ),
                  }
                } else {
                  // Original weekly view
                  return {
                    label: `W${currentWeek}`,
                    ...lodash.fromPairs(
                      valuesKeys.map((key) => [
                        key,
                        average(
                          feedbackStats?.weekDetails
                            .find((elem) => elem.week === currentWeek)
                            ?.companyValuesScore?.filter(Boolean) // Filter out null/undefined scores
                            ?.map((sentiment) => sentiment[key]) ?? [],
                        ),
                      ]),
                    ),
                  }
                }
              })}
              lines={valuesKeys}
              config={lodash.fromPairs(
                valuesKeys.map((value, idx) => [
                  value,
                  { label: value, color: LINE_CHART_COLORS[idx] },
                ]),
              )}
              domain={[0, 5]}
              legend
              type="monotone"
              initialHiddenLines={valuesKeys.slice(1)}
              storageKey="dashboard.company-values-chart.hidden-lines"
            />
          ) : (
            <div className="h-full flex items-center justify-center">
              <span className="text-muted-foreground">
                No values data available for the selected time period
              </span>
            </div>
          )}
        </Tile>
        <Tile className="h-[300px]" isLoading={isLoadingStats}>
          <Muted>Quality score over time</Muted>
          <LineChart
            data={dateIntervalsToShow.map((week) => {
              const weekDetails = feedbackStats?.weekDetails.find(
                (elem) => elem.week === moment(week).week(),
              )

              // Calculate total scores for the week, only for complete scores
              const totalScores = weekDetails?.checklistScores.reduce(
                (acc, score) => {
                  // Only count scores that have all three values
                  if (
                    typeof score.yes === 'number' &&
                    typeof score.no === 'number' &&
                    typeof score.partial === 'number'
                  ) {
                    return {
                      yes: acc.yes + score.yes,
                      no: acc.no + score.no,
                      partial: acc.partial + score.partial,
                    }
                  }
                  return acc
                },
                { yes: 0, no: 0, partial: 0 },
              ) ?? { yes: 0, no: 0, partial: 0 }

              // Calculate compliance score only if we have valid scores
              const total =
                totalScores.yes + totalScores.no + totalScores.partial
              const complianceScore =
                total > 0
                  ? Number(
                      (
                        (totalScores.yes + totalScores.partial * 0.5) /
                        total
                      ).toFixed(2),
                    )
                  : null

              return {
                label: `W${moment(week).week()}`,
                averageCompliance: complianceScore,
              }
            })}
            lines={['averageCompliance']}
            config={{
              averageCompliance: {
                label: 'Average Quality Score',
                color: colors.blue['600'],
              },
            }}
            domain={[0, 1]}
            type="monotone"
            referenceLine={{
              y: 0.7,
              stroke: colors.gray['400'],
              strokeDasharray: '3 3',
              label: { value: 'Target: 0.7', position: 'insideTopRight' },
            }}
            storageKey="dashboard.quality-score-chart.hidden-lines"
          />
        </Tile>
      </div>
    </>
  )
}
