import { z } from 'zod'

export const UpdateCompanyValueSchema = z.object({
  label: z.string().min(5),
  description: z.string().optional().default(''),
  is: z.string().optional().default(''),
  isNot: z.string().optional().default(''),
})

export const UpdateCompanyValuesSchema = z.object({
  values: z
    .array(UpdateCompanyValueSchema)
    .min(1, { message: 'At least one value is required' }),
})

export type UpdateCompanyValuesSchemaType = z.infer<
  typeof UpdateCompanyValuesSchema
>
