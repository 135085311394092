import { useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { H4 } from '@/components/ui/typography'
import { Modal } from '@/components/common/modal'
import { Interval, Quarter } from '@/services/utils/dates'

import { TeamObjectiveCreateSchema } from '@/lib/schema/objective-form.schema'

import { useCreateObjective } from '@/services/api/objective.api'

import { SetState } from '@/types/globals'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select'
import { TeamMemberSelector } from './team-user-selector'
import { UserProfile } from '@/types/UserProfile'

interface NewObjectiveModalProps {
  isOpen: boolean
  setIsOpen: SetState<boolean>
  intervals: Interval[]
  selectedQuarter: Quarter
}

type TeamObjectiveSchemaAlias = z.infer<typeof TeamObjectiveCreateSchema>

export const NewObjectiveModal = ({
  isOpen,
  setIsOpen,
  intervals,
  selectedQuarter,
}: NewObjectiveModalProps) => {
  const { mutate: createObjective, isPending } =
    useCreateObjective(selectedQuarter)

  function onSubmit(values: TeamObjectiveSchemaAlias) {
    createObjective(values, {
      onSuccess: () => setIsOpen(false),
    })
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="flex flex-col gap-2">
        <H4 className="font-semibold">Add new Objective</H4>
        <ObjectiveModalForm
          onSubmit={onSubmit}
          onCancel={() => setIsOpen(false)}
          defaultValues={{
            type: 'average',
            values: intervals.map((interval) => ({
              startDate: interval.startDate.format(),
              weekNumber: interval.weekNumber,
            })),
            owners: [],
            year: selectedQuarter.year,
            quarter: selectedQuarter.quarter,
          }}
          isPending={isPending}
        />
      </div>
    </Modal>
  )
}

export const ObjectiveModalForm = ({
  defaultValues,
  onSubmit,
  onCancel,
  isPending,
}: {
  defaultValues?: Partial<TeamObjectiveSchemaAlias>
  onSubmit: (_values: TeamObjectiveSchemaAlias) => void
  onCancel: () => void
  isPending?: boolean
}) => {
  const [selectedOwners, setSelectedOwners] = useState<UserProfile[]>([])
  const form = useForm<TeamObjectiveSchemaAlias>({
    resolver: zodResolver(TeamObjectiveCreateSchema),
    defaultValues: defaultValues ?? {},
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="p-2 space-y-3">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Objective</FormLabel>
              <FormControl>
                <Input
                  placeholder="Increase customer satisfaction"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="owners"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Owner(s)</FormLabel>
              <TeamMemberSelector
                onChange={(newValue: UserProfile[]) => {
                  field.onChange(newValue)
                  setSelectedOwners(newValue)
                }}
                value={selectedOwners}
                noPortal
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Aggregation method</FormLabel>
              <Select value={field.value} onValueChange={field.onChange}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="average">Average</SelectItem>
                  <SelectItem value="lastColumn">Last Column</SelectItem>
                  <SelectItem value="sum">Sum</SelectItem>
                </SelectContent>
              </Select>
              <FormDescription>
                Select how you want to aggregate the data
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="target"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Target</FormLabel>
              <FormControl>
                <Input
                  placeholder="100"
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              </FormControl>
              <FormDescription>
                Define the target achievement for the objective
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex gap-4 pt-2">
          <Button type="submit" disabled={!!isPending}>
            Save
          </Button>
          <Button
            variant="outline"
            onClick={(e) => {
              e.preventDefault()
              onCancel()
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  )
}
