import { DateRange } from 'react-day-picker'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'
import { useState, useRef, useMemo } from 'react'

import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { Select } from '@/components/common/select'

interface DatePickerProps {
  value: DateRange
  selectedPreset: string
  onChange: (
    _dateRange: DateRange,
    _preset: string,
    _isAllTime?: boolean,
  ) => void
  includeAllTime?: boolean
}

const MOMENT_DATE_FORMAT = 'MMM D, YYYY'

export const DatePicker = ({
  value,
  selectedPreset,
  onChange,
  includeAllTime = false,
}: DatePickerProps) => {
  const [localSelectedPreset, setLocalSelectedPreset] = useState(selectedPreset)
  const calendarRef = useRef<HTMLDivElement>(null)

  const getPresetDateRange = (preset: string): DateRange => {
    switch (preset) {
      case 'all-time':
        return {
          from: new Date('2024-01-01'),
          to: new Date(),
        }
      case 'last-month':
        return {
          from: moment().subtract(1, 'month').startOf('month').toDate(),
          to: moment().subtract(1, 'month').endOf('month').toDate(),
        }
      case 'last-3-months':
        return {
          from: moment().subtract(3, 'months').startOf('day').toDate(),
          to: moment().endOf('day').toDate(),
        }
      case 'last-quarter':
        return {
          from: moment().subtract(1, 'quarter').startOf('quarter').toDate(),
          to: moment().subtract(1, 'quarter').endOf('quarter').toDate(),
        }
      case 'this-quarter':
        return {
          from: moment().startOf('quarter').toDate(),
          to: moment().endOf('quarter').toDate(),
        }
      case 'this-year':
        return {
          from: moment().startOf('year').toDate(),
          to: moment().endOf('year').toDate(),
        }
      default:
        return {
          from: undefined,
          to: undefined,
        }
    }
  }

  const presets = useMemo(
    () => [
      ...(includeAllTime
        ? [
            {
              label: 'All time',
              value: 'all-time',
            },
          ]
        : []),
      {
        label: 'Last month',
        value: 'last-month',
      },
      {
        label: 'Last 3 months',
        value: 'last-3-months',
      },
      {
        label: 'Last quarter',
        value: 'last-quarter',
      },
      {
        label: 'This quarter',
        value: 'this-quarter',
      },
      {
        label: 'This year',
        value: 'this-year',
      },
    ],
    [includeAllTime],
  )

  const formatDateRange = (range: DateRange) => {
    const { from, to } = range
    if (localSelectedPreset === 'all-time') return 'All Time (2024)'
    if (!from) return 'Pick a date'
    if (!to) return `From ${moment(from).format(MOMENT_DATE_FORMAT)}`
    return `${moment(from).format(MOMENT_DATE_FORMAT)} - ${moment(to).format(MOMENT_DATE_FORMAT)}`
  }

  const handlePresetChange = (value: string) => {
    const preset = presets.find((preset) => preset.value === value)
    if (preset) {
      const dateRange = getPresetDateRange(value)
      onChange(dateRange, value, value === 'all-time')
      setLocalSelectedPreset(value)
      // Update calendar focus
      if (calendarRef.current) {
        const calendar = calendarRef.current.querySelector(
          '.rdp-month',
        ) as HTMLElement
        if (calendar) {
          calendar.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          id="date"
          variant={'outline'}
          className="w-full justify-start text-left font-normal"
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {localSelectedPreset
            ? presets.find((preset) => preset.value === localSelectedPreset)
                ?.label
            : formatDateRange(value)}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <div
          role="heading"
          aria-level={2}
          className="px-2 pt-2 text-sm font-medium"
        >
          Select Date Range
        </div>
        <div className="space-y-2 p-2">
          <Select
            options={presets.map((preset) => ({
              value: preset.value,
              label: preset.label,
            }))}
            value={localSelectedPreset}
            onValueChange={handlePresetChange}
            placeholder="Select a preset"
            className="w-full"
          />
          <div ref={calendarRef}>
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={value.from}
              selected={value}
              onSelect={(newValue) => {
                if (newValue) {
                  onChange(newValue, '', false)
                  setLocalSelectedPreset('')
                }
              }}
              numberOfMonths={2}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
